import { MandateObjModel } from "./mandateModel";
import { ObjectModel } from "./objectModel";

export interface UserModel {
  id?: string;
  name: string;
  surname: string;
  email: string;
  password?: string;
  phone: string;
  privacy: boolean;
  terms: boolean;
  marketing?: boolean;
  assignedPromoCode?: string
  group?: number,
  enabled?: boolean,
  vat?: string;
  address?: string;
  city?: string;
  zipCode?: string;
  province?: string;
  taxCode?: string;
  pec?: string;
  atecos: string[];
  fund?: string;
  recourseValue?: number;
  bankAccount: BankAccount[];
  taxation?: number;
  subscriptionPeriod?: number;
  renewals?: string[];
  lastPayment?: string;
  lang?: string;
  registrationDate?: string;
  flatTaxLimit?: number;
  profilePickId?: string;
  dateOfBirth?: string;
  birthState?: string;
  birthPlace?: string;
  birthProvince?: string;
  gender?: string;
  acquisitionChannel?: string;
  promoCode?: string;
  notes?: string;
  vatOpeningDate?: string;
  initialInvoiceNumber?: string;
  initialInvoiceNumberSTS?: string;
  status?: number;
  category?: CategoryType,
  contributionReduction?: boolean,
  contributionExemption?: boolean,
  ddrDisabled?: boolean
  fiscalRegime?: string;
  viesSubscription?: boolean;
  viesSubscriptionDate?: string;
  intrastatForeignActivities?: boolean;
  ddrYears?: number[];
  idDocId?: string;
  taxDocId?: string;
  validationFormStatus?: number;
  incomeFormCompleted?: boolean;
  latestTotalIncome?: LatestTotalIncome
  customNumeration?: boolean;
  stsEnabled?: boolean;
  regimeLimit?: RegimeLimitModel;
  addressExtra?: AddressExtraModel;
  previousVatStatus?: string;
  lastYearTaxation?: string;
  docIdType?: string;
  docIdNumber?: string;
  docIdReleasedBy?: string;
  docIdReleasedDate?: Date;
  docIdExpirationDate?: Date;
  productIds?: string[];
  idDoc?: DocModel;
  taxDoc?: DocModel;
  contractDoc?: DocModel;
  registrationSteps?: RegistrationStepsModel;
  inpsFundType?: InpsFundDescriptionType;
  companyName?: string;
  electronicInvoicing?: MandateObjModel;
  fiscalDrawer?: MandateObjModel;
}

export interface FundModel {
  desc: string;
  recourseValue?: number;
}

export interface AtecoModel {
  desc: string;
}

export enum UserStatusType {
  REGISTERED = 1,
  CONFIRMED = 2,
  PAYMENT_DUE = 3,
  ACTIVE = 4,
  INACTIVE = 5,
  ONBOARDING = 6,
}

export enum CategoryType {
  FUND = 'FUND',
  NOT_FUND = 'NOT_FUND',
  TRADER = 'TRADER',
  ARTISAN = 'ARTISAN',
}

export interface ObjIncomeUpload {
  feesRegisterTotalInvoices?: number;
  telematicFeesRegister?: number;
  objectModel: ObjectModel[];
  file?: string[]
}

export interface ObjDdrDocument {
  objectId: string;
  fileName: string;
  createdAt?: Date;
}

export interface LatestTotalIncome {
  year: number
  amount: number
}

export interface StatusDdrModel {
  status: number;
  statusDate: Date;
  createAtIncomes: Date;
}

export interface DdrDocumentsModel {
  documentsFile: ObjDdrDocument[];
}

export interface RegimeLimitModel {
  createdAt: Date;
  status: RegimeLimitStatusType;
}

export enum RegimeLimitStatusType {
  PENDING = 'PENDING',
  OVER85k = 'OVER85k',
  OVER100k = 'OVER100k',
}

export interface AddressExtraModel {
  cityCodCatastale: string;
  cityRegion: string;
  cityRegionCode: string;
}

export interface DocModel {
  objectId?: string;
  type?: OnboardingDocTypeModel;
  number?: string;
  releasedBy?: string;
  releaseAt?: Date;
  expiredAt?: Date;
  youSignSignatureRequestId?: string;
  createdAt?: Date;
}

export enum OnboardingDocTypeModel {
  IDENTITY_CARD = 'identity_card',
  PASSPORT = 'passport',
  RESIDENCY_PERMIT = 'residency_permit',
  OTHER = 'other',
}

export interface RegistrationStepsModel {
  account_created: boolean;
  email_verification: boolean;
  profile_registry: boolean;
  profile_fiscal: boolean;
  your_documents: boolean;
  contract: boolean;
  payment: boolean;
}
export interface BankAccount {
  name: string;
  iban: string;
  default?: boolean;
}

export enum InpsFundDescriptionType {
  NOT_FUND = 'NOT_FUND',
  ARTISAN_FUND = 'ARTISAN_FUND',
  TRADER_FUND = 'TRADER_FUND',
  EX_ENPALS_FUND = 'EX_ENPALS_FUND',
}